var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-content',{staticClass:"floor-plan-design-edit",attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"p-3"},[_vm._v("Edit Design")])]},proxy:true},{key:"body",fn:function(){return [_c('fd-form',{ref:"editFpDesignForm",staticClass:"row p-3",on:{"submit":_vm.submitForm}},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Name","name":"designName","type":"text","validators":[_vm.validator.required]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('fd-input',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"3D Panorama Link","name":"panoramaLink","type":"text","validators":[_vm.validator.website]},model:{value:(_vm.form.panoramaLink),callback:function ($$v) {_vm.$set(_vm.form, "panoramaLink", $$v)},expression:"form.panoramaLink"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Renovation Cost/Fee","name":"estimatePrice","type":"number","validators":[_vm.validator.price]},model:{value:(_vm.form.estimatePrice),callback:function ($$v) {_vm.$set(_vm.form, "estimatePrice", $$v)},expression:"form.estimatePrice"}}),_c('fd-textarea',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"Design Concept","name":"description","type":"number"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('image-uploader',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"Main Photo","multiple":false,"required":""},on:{"loading":function (val) {
            _vm.isLoading = val;
          },"error":function (error, imageName) {
            _vm.$reportError(error, '[Designer CP] Upload FP Design Main Photo');
          }},model:{value:(_vm.form.mainPhoto),callback:function ($$v) {_vm.$set(_vm.form, "mainPhoto", $$v)},expression:"form.mainPhoto"}}),_c('image-uploader',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"Gallery Photo","multiple":""},on:{"loading":function (val) {
            _vm.isLoading = val;
          },"error":function (error, imageName) {
            _vm.$reportError(error, '[Designer CP] Upload FP Design Gallery');
          }},model:{value:(_vm.form.gallery),callback:function ($$v) {_vm.$set(_vm.form, "gallery", $$v)},expression:"form.gallery"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end p-2"},[_c('fd-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")]),_c('fd-button',{staticClass:"medium main",attrs:{"loadingEnabled":"","isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.editFpDesignForm.submit()}}},[(!_vm.isLoading)?_c('i',{staticClass:"fas fa-pen mr-1"}):_vm._e(),_vm._v(" Update Design ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }