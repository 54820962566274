<template>
  <modal-content class="floor-plan-design-edit" fullscreen>
    <template #header>
      <h4 class="p-3">Edit Design</h4>
    </template>

    <template #body>
      <fd-form ref="editFpDesignForm" class="row p-3" @submit="submitForm">
        <fd-input
          v-model="form.name"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Name"
          name="designName"
          type="text"
          :validators="[validator.required]"
        >
        </fd-input>
        <fd-input
          v-model="form.panoramaLink"
          class="col-12 px-1 mb-2"
          label="3D Panorama Link"
          name="panoramaLink"
          type="text"
          :validators="[validator.website]"
        >
        </fd-input>
        <fd-input
          v-model="form.estimatePrice"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Renovation Cost/Fee"
          name="estimatePrice"
          type="number"
          :validators="[validator.price]"
        >
        </fd-input>
        <fd-textarea
          v-model="form.description"
          class="col-12 px-1 mb-2"
          label="Design Concept"
          name="description"
          type="number"
        >
        </fd-textarea>
        <image-uploader
          class="col-12 px-1 mb-2"
          v-model="form.mainPhoto"
          label="Main Photo"
          :multiple="false"
          required
          @loading="
            (val) => {
              isLoading = val;
            }
          "
          @error="
            (error, imageName) => {
              $reportError(error, '[Designer CP] Upload FP Design Main Photo');
            }
          "
        ></image-uploader>
        <image-uploader
          class="col-12 px-1 mb-2"
          v-model="form.gallery"
          label="Gallery Photo"
          multiple
          @loading="
            (val) => {
              isLoading = val;
            }
          "
          @error="
            (error, imageName) => {
              $reportError(error, '[Designer CP] Upload FP Design Gallery');
            }
          "
        ></image-uploader>
      </fd-form>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end p-2">
        <fd-button class="mr-2" @click="$emit('close')">Close</fd-button>
        <fd-button
          loadingEnabled
          :isLoading="isLoading"
          class="medium main"
          @click="$refs.editFpDesignForm.submit()"
        >
          <i v-if="!isLoading" class="fas fa-pen mr-1"></i> Update Design
        </fd-button>
      </div>
    </template>
  </modal-content>
</template>

<script>
import {
  required,
  price,
  website
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { FloorPlanDesignModel } from "@/models";

export default {
  components: {
    ModalContent: () =>
      import("@/components/GlobalComponents/ModalBox/ModalContent")
  },
  mixins: [],
  props: {
    design: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      form: {
        id: "",
        name: "",
        panoramaLink: "",
        mainPhoto: [],
        gallery: [],
        estimatePrice: "",
        description: ""
      },

      validator: {
        required: required,
        price: price,
        website: website
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.form = FloorPlanDesignModel.getToEdit(this.design);
  },
  methods: {
    submitForm() {
      this.$emit("submit", this.form);
    }
  }
};
</script>

<style lang="scss">
</style>
